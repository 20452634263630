.header-wrap {
  display: flex;
  justify-content: space-between;
  padding: 2px 30px 2px 0px
}

.header-left {
  display: flex;
  align-items: center
}

.header-left-homepage {
  margin-left: 50px
}

.header-left-homepage>a {
  color: inherit;
  font-size: 20px;
  font-weight: bold;
  text-decoration: none
}

.header-right {
  display: flex;
  align-items: center;
  cursor: pointer;
}

@media screen and (min-width: 769px) {
  .header-left-icon {
    display: none
  }
}

@media screen and (max-width: 768px) {
  .header-left-homepage {
    margin-left: 20px
  }

}