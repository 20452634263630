.avartar {
  width: 30px;
  height: 30px;
  border-radius: 50%;

}

.avartar-img {
  width: 100%;
  height: 100%
}