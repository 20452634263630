.left-side-bar {
  overflow: hidden;
  white-space: nowrap;
}

.left-side-bar__avartar {
  margin: 10px 0px 10px 10px;
  display: flex;
  white-space: nowrap;
  float: left;
  align-items: center;
}

.left-side-bar__avartar__text {
  margin: 10px 0 0 15px;
}

.left-side-bar__avartar__text>h5 {
  line-height: 1;
  margin-bottom: 1px;
}

.left-side-bar__avartar__text>span {
  font-size: 14px;
}

.left-side-bar__avartar__text>span>a {
  color: #fff;
  text-decoration: none;
}

.left-side-bar__item {
  width: 100%;
  padding: 10px 0;
  white-space: nowrap;
  overflow: hidden;
  /* margin-left: 20px; */
  margin-bottom: 10px;
}

.left-side-bar__item.bnt-active {
  background: #034963;
}

.left-side-bar__item.bnt-active .left-side-bar__item-wrap__text {
  color: #3bc8f7;
}

.left-side-bar__item>div {
  margin-left: 20px;
}

.left-side-bar__item-wrap {
  display: flex;
  align-items: center;
  margin-left: -10px;
}

.left-side-bar__item-wrap>img {
  margin-left: 0px;
  width: 25px;
  height: 25px;
}

.left-side-bar__item-wrap__text {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-left: 20px;
  overflow: hidden;
}

.left-side-bar__item-wrap__text>div:first-child {
  flex: 4;
}

.left-side-bar__item-wrap__text>div:last-child {
  flex: 1;
}

.left-side-bar__item-wrap__text>.arrow-wrap>.active {
  border-color: #3bc8f7;
}

.menu-badge {
  margin-left: 150px;
  margin-bottom: -15px;
}

.menu-badge span {
  min-width: 25px;
  min-height: 10px;
  padding: 5px;
  margin: 3px;
}

.collapse-menu {
  display: flex;
  margin-left: 50px;
  padding: 10px 0;

}

.collapse-menu>img {
  width: 20px;
  height: 20px;
}

.collapse-menu>a {
  margin-left: 13px;
  color: #fff;
  text-decoration: none;
}