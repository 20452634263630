::-webkit-scrollbar {
  -webkit-appearance: none;
  height: 5px;
  width: 5px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

html {
  scroll-behavior: smooth;
  scroll-behavior: -webkit-smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #efefee; */
  background-color: #fff;
}

html,
body {
  height: 100%;
  margin: 0;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.display-flex {
  display: flex;
}

.display-flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.full-100vh {
  height: 100vh;
  width: 100%;
}

.full-content-width {
  width: 100%;
}

.row {
  margin: 0;
  padding: 0;
}

@media screen and (max-width: 768px) {

  .col,
  .col-sm-12 {
    margin: 0;
    padding: 0;
  }
}

@media (max-width: 768px) {

  .col-md-6,
  .col-md-3,
  .col-md-9 {
    -webkit-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .col-sm-6,
  .col-xs-12 {
    padding: 0;
  }

  .col-sm-6,
  .col-sm-12,
  .col-xs-12 {
    margin: 5px 0;
  }
}

.cursor-poiter {
  cursor: pointer;
}

.cursor-poiter:hover {
  opacity: 0.8;
}

@media screen and (min-width: 769px) {
  .page-wrapper {
    display: flex;
    flex-flow: column;
    height: 100%;
    overflow: hidden;
  }

  .page-wrapper .page-row {
    flex: 0 1 auto;
  }

  .page-title {
    height: 100px;
  }

  .page-title-auto-height {
    height: auto;
    margin: 10px 0;
  }
}

.page-title {
  color: #045f80;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bnt-save {
  background-color: #09972c !important;
}

.bnt-cancel {
  background-color: red !important;
}

.bnt-edit {
  background-color: #ff851a !important;
}

.bnt-submit {
  background-color: #3c8dbc !important;
}

.bnt-inactive {
  background-color: #474e49 !important;
  color: #fff !important;

}

.bnt-icon {
  height: 35px !important;
  font-size: 16px !important;
  max-width: 115px !important;
}

.bnt-action-wrrap {
  display: flex;
}

.bnt-action-wrrap>button:first-child {
  margin-right: 4px;
}

.close:focus {
  outline: none;
}

.button-print {
  width: max-content;
  height: 40px;
  border: none;
  outline: none;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  background-color: #3c8dbc;
  color: #fff;
  font-size: 18px;
}

.button-print:focus {
  outline: none;
}

.body-print {
  padding: 10px 300px 0 10px;
}

.NotYet {
  color: #178816;
  font-weight: bold;
}

.Expire {
  color: Red;
  font-weight: bold;
}

.Prepare {
  color: #384cf9;
  font-weight: bold;
}

.Near {
  color: #34495e;
  font-weight: bold;
}

.active {
  color: #178816;
  font-weight: bold;
}

.inactive {
  color: #34495e;
  font-weight: bold;
}

.input-error {
  border: 1px solid red !important;
}

.text-error {
  color: red !important;
}

.Immediately {
  color: red;
  font-weight: bold;

}

.Prepare {
  color: #ff851a;
  font-weight: bold;

}

.InStock {
  color: #178816;
  font-weight: bold;

}

.disable-input {
  color: rgb(153, 145, 145) !important;
}

.font-weight-bold {
  font-weight: bold
}

.mg-bottom-15 {
  margin-bottom: 15px;
}