.display-flex {
  display: flex;
}

.display-flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-white {
  color: #fff;
}