.app-wrapper {
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 10;
  color: #fff;
}

.app-header {
  width: 100%;
  height: 40px;
  background: #045e80;
  position: fixed;
  z-index: 3;
  display: flex;
  align-items: center;
}

.app-header-icon {
  margin-left: 20px;
  width: 100%;
}

.app-footer {
  width: 100%;
  position: fixed;
  bottom: 0;
  background: black;
}

.app-nav-left {
  width: 50px;
  background: #045e80;
  height: 100%;
  position: fixed;
  z-index: 12;
  top: 0;
  transition: 250ms;
  -webkit-transition: 250ms;
  -moz-transition: 250ms;
  -ms-transition: 250ms;
  -o-transition: 250ms;
}

.app-overlay {
  width: 100%;
  /*min-height: 100vh;
  */
  height: 100%;
  position: absolute;
  background-color: transparent;
  transition: 100ms;
  -webkit-transition: 100ms;
  -moz-transition: 100ms;
  -ms-transition: 100ms;
  -o-transition: 100ms;
  z-index: -1;
}

.app-overlay.show {
  z-index: 10;
  opacity: 0.3;
  background-color: #303030;
}

.app-nav-left:hover {
  width: 270px;
}

.app-main {
  width: 100%;
  height: 100vh;
  position: relative;
  /* padding: 60px 10px 30px 70px */
}

.app-main-content {
  padding: 42px 10px 0px 60px;
  height: 100%;
}

@media only screen and (max-width: 768px) {
  .app-nav-left {
    width: 0;
    transition: 250ms;
    -webkit-transition: 250ms;
    -moz-transition: 250ms;
    -ms-transition: 250ms;
    -o-transition: 250ms;
  }

  .app-nav-left.toogle {
    width: 270px;
  }

  .app-nav-left.toogle .app-overlay {
    background-color: #303030;
    z-index: 5;
  }

  .app-main-content {
    padding: 59px 0px 30px 0px;
  }
}