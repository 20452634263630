/* .toast-noti {
  display: flex;
  border-radius: 8px;
  width: 100%;
  align-items: center;
  &-icon {
    background-color: white;
    border-radius: 50%;
    margin-left: 21px;
    margin-right: 22px;
    img {
      width: 32px;
      height: 32px;
    }
  }
  &-content {
    font-size: 16px;
    font-weight: normal;
    color: #ffffff;
  }
} */
.toast-noti,
.toast-noti:hover {
  color: #fff;
  text-decoration: none;
}


.Toastify__toast--default.toast-modify {
  background-color: #4e4e4e;
  border-radius: 8px;
  width: 323px;
  height: 52px;
  font-family: DaxVN;
}

.Toastify__close-button.Toastify__close-button--default {
  color: #ffffff;
  opacity: 1;
  width: 9px;
  height: 9px;
}

@media only screen and (max-width: 480px) {
  .Toastify__toast-container.Toastify__toast-container--top-right {
    width: auto !important;
    padding: 0;
    left: auto;
  }
}
::-webkit-scrollbar {
  -webkit-appearance: none;
  height: 5px;
  width: 5px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

html {
  scroll-behavior: smooth;
  scroll-behavior: -webkit-smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #efefee; */
  background-color: #fff;
}

html,
body {
  height: 100%;
  margin: 0;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.display-flex {
  display: -webkit-flex;
  display: flex;
}

.display-flex-center {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.full-100vh {
  height: 100vh;
  width: 100%;
}

.full-content-width {
  width: 100%;
}

.row {
  margin: 0;
  padding: 0;
}

@media screen and (max-width: 768px) {

  .col,
  .col-sm-12 {
    margin: 0;
    padding: 0;
  }
}

@media (max-width: 768px) {

  .col-md-6,
  .col-md-3,
  .col-md-9 {
    -webkit-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .col-sm-6,
  .col-xs-12 {
    padding: 0;
  }

  .col-sm-6,
  .col-sm-12,
  .col-xs-12 {
    margin: 5px 0;
  }
}

.cursor-poiter {
  cursor: pointer;
}

.cursor-poiter:hover {
  opacity: 0.8;
}

@media screen and (min-width: 769px) {
  .page-wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column;
            flex-flow: column;
    height: 100%;
    overflow: hidden;
  }

  .page-wrapper .page-row {
    -webkit-flex: 0 1 auto;
            flex: 0 1 auto;
  }

  .page-title {
    height: 100px;
  }

  .page-title-auto-height {
    height: auto;
    margin: 10px 0;
  }
}

.page-title {
  color: #045f80;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.bnt-save {
  background-color: #09972c !important;
}

.bnt-cancel {
  background-color: red !important;
}

.bnt-edit {
  background-color: #ff851a !important;
}

.bnt-submit {
  background-color: #3c8dbc !important;
}

.bnt-inactive {
  background-color: #474e49 !important;
  color: #fff !important;

}

.bnt-icon {
  height: 35px !important;
  font-size: 16px !important;
  max-width: 115px !important;
}

.bnt-action-wrrap {
  display: -webkit-flex;
  display: flex;
}

.bnt-action-wrrap>button:first-child {
  margin-right: 4px;
}

.close:focus {
  outline: none;
}

.button-print {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  height: 40px;
  border: none;
  outline: none;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  background-color: #3c8dbc;
  color: #fff;
  font-size: 18px;
}

.button-print:focus {
  outline: none;
}

.body-print {
  padding: 10px 300px 0 10px;
}

.NotYet {
  color: #178816;
  font-weight: bold;
}

.Expire {
  color: Red;
  font-weight: bold;
}

.Prepare {
  color: #384cf9;
  font-weight: bold;
}

.Near {
  color: #34495e;
  font-weight: bold;
}

.active {
  color: #178816;
  font-weight: bold;
}

.inactive {
  color: #34495e;
  font-weight: bold;
}

.input-error {
  border: 1px solid red !important;
}

.text-error {
  color: red !important;
}

.Immediately {
  color: red;
  font-weight: bold;

}

.Prepare {
  color: #ff851a;
  font-weight: bold;

}

.InStock {
  color: #178816;
  font-weight: bold;

}

.disable-input {
  color: rgb(153, 145, 145) !important;
}

.font-weight-bold {
  font-weight: bold
}

.mg-bottom-15 {
  margin-bottom: 15px;
}
.loading-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  background-color: #303030;
  opacity: 0.3;
  z-index: 1000;
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}

.lds-roller div {
  -webkit-animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  -webkit-transform-origin: 32px 32px;
          transform-origin: 32px 32px;
}

.lds-roller div:after {
  content: ' ';
  display: block;
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #fff;
  margin: -3px 0 0 -3px;
}

.lds-roller div:nth-child(1) {
  -webkit-animation-delay: -0.036s;
          animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 50px;
  left: 50px;
}

.lds-roller div:nth-child(2) {
  -webkit-animation-delay: -0.072s;
          animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 54px;
  left: 45px;
}

.lds-roller div:nth-child(3) {
  -webkit-animation-delay: -0.108s;
          animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 57px;
  left: 39px;
}

.lds-roller div:nth-child(4) {
  -webkit-animation-delay: -0.144s;
          animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 58px;
  left: 32px;
}

.lds-roller div:nth-child(5) {
  -webkit-animation-delay: -0.18s;
          animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 57px;
  left: 25px;
}

.lds-roller div:nth-child(6) {
  -webkit-animation-delay: -0.216s;
          animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 54px;
  left: 19px;
}

.lds-roller div:nth-child(7) {
  -webkit-animation-delay: -0.252s;
          animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 50px;
  left: 14px;
}

.lds-roller div:nth-child(8) {
  -webkit-animation-delay: -0.288s;
          animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 45px;
  left: 10px;
}

@-webkit-keyframes lds-roller {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes lds-roller {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.arrow-wrap {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

i {
  border: solid #fff;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  -webkit-transition: 250ms;
  transition: 250ms;
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.avartar {
  width: 30px;
  height: 30px;
  border-radius: 50%;

}

.avartar-img {
  width: 100%;
  height: 100%
}
.left-side-bar {
  overflow: hidden;
  white-space: nowrap;
}

.left-side-bar__avartar {
  margin: 10px 0px 10px 10px;
  display: -webkit-flex;
  display: flex;
  white-space: nowrap;
  float: left;
  -webkit-align-items: center;
          align-items: center;
}

.left-side-bar__avartar__text {
  margin: 10px 0 0 15px;
}

.left-side-bar__avartar__text>h5 {
  line-height: 1;
  margin-bottom: 1px;
}

.left-side-bar__avartar__text>span {
  font-size: 14px;
}

.left-side-bar__avartar__text>span>a {
  color: #fff;
  text-decoration: none;
}

.left-side-bar__item {
  width: 100%;
  padding: 10px 0;
  white-space: nowrap;
  overflow: hidden;
  /* margin-left: 20px; */
  margin-bottom: 10px;
}

.left-side-bar__item.bnt-active {
  background: #034963;
}

.left-side-bar__item.bnt-active .left-side-bar__item-wrap__text {
  color: #3bc8f7;
}

.left-side-bar__item>div {
  margin-left: 20px;
}

.left-side-bar__item-wrap {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-left: -10px;
}

.left-side-bar__item-wrap>img {
  margin-left: 0px;
  width: 25px;
  height: 25px;
}

.left-side-bar__item-wrap__text {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  width: 100%;
  margin-left: 20px;
  overflow: hidden;
}

.left-side-bar__item-wrap__text>div:first-child {
  -webkit-flex: 4 1;
          flex: 4 1;
}

.left-side-bar__item-wrap__text>div:last-child {
  -webkit-flex: 1 1;
          flex: 1 1;
}

.left-side-bar__item-wrap__text>.arrow-wrap>.active {
  border-color: #3bc8f7;
}

.menu-badge {
  margin-left: 150px;
  margin-bottom: -15px;
}

.menu-badge span {
  min-width: 25px;
  min-height: 10px;
  padding: 5px;
  margin: 3px;
}

.collapse-menu {
  display: -webkit-flex;
  display: flex;
  margin-left: 50px;
  padding: 10px 0;

}

.collapse-menu>img {
  width: 20px;
  height: 20px;
}

.collapse-menu>a {
  margin-left: 13px;
  color: #fff;
  text-decoration: none;
}
.hamburger {
  height: 18px;
  width: 18px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  cursor: pointer;
}

.hamburger .navicon {
  background: #fff;
  display: block;
  height: 2px;
  position: relative;
  -webkit-transition: 0.2s ease-out;
  transition: 0.2s ease-out;
  width: 100%;
}

.hamburger .navicon::before,
.hamburger .navicon::after {
  background: #fff;
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  width: 100%;
}

.hamburger .navicon::before {
  top: 5px;
}

.hamburger .navicon::after {
  top: -5px;
}

.hamburger.toogle .navicon {
  background: transparent;
}

.hamburger.toogle .navicon:before {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

.hamburger.toogle .navicon:after {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.hamburger.toogle .navicon:before,
.hamburger.toogle .navicon:after {
  top: 0;
}
.icon-text {
  margin-left: 3px;
}

@media (max-width: 768px) {
  .icon-text {
    font-size: 15px;
  }
}
.header-wrap {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 2px 30px 2px 0px
}

.header-left {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center
}

.header-left-homepage {
  margin-left: 50px
}

.header-left-homepage>a {
  color: inherit;
  font-size: 20px;
  font-weight: bold;
  text-decoration: none
}

.header-right {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer;
}

@media screen and (min-width: 769px) {
  .header-left-icon {
    display: none
  }
}

@media screen and (max-width: 768px) {
  .header-left-homepage {
    margin-left: 20px
  }

}
.app-wrapper {
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 10;
  color: #fff;
}

.app-header {
  width: 100%;
  height: 40px;
  background: #045e80;
  position: fixed;
  z-index: 3;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.app-header-icon {
  margin-left: 20px;
  width: 100%;
}

.app-footer {
  width: 100%;
  position: fixed;
  bottom: 0;
  background: black;
}

.app-nav-left {
  width: 50px;
  background: #045e80;
  height: 100%;
  position: fixed;
  z-index: 12;
  top: 0;
  transition: 250ms;
  -webkit-transition: 250ms;
  -moz-transition: 250ms;
  -ms-transition: 250ms;
  -o-transition: 250ms;
}

.app-overlay {
  width: 100%;
  /*min-height: 100vh;
  */
  height: 100%;
  position: absolute;
  background-color: transparent;
  transition: 100ms;
  -webkit-transition: 100ms;
  -moz-transition: 100ms;
  -ms-transition: 100ms;
  -o-transition: 100ms;
  z-index: -1;
}

.app-overlay.show {
  z-index: 10;
  opacity: 0.3;
  background-color: #303030;
}

.app-nav-left:hover {
  width: 270px;
}

.app-main {
  width: 100%;
  height: 100vh;
  position: relative;
  /* padding: 60px 10px 30px 70px */
}

.app-main-content {
  padding: 42px 10px 0px 60px;
  height: 100%;
}

@media only screen and (max-width: 768px) {
  .app-nav-left {
    width: 0;
    transition: 250ms;
    -webkit-transition: 250ms;
    -moz-transition: 250ms;
    -ms-transition: 250ms;
    -o-transition: 250ms;
  }

  .app-nav-left.toogle {
    width: 270px;
  }

  .app-nav-left.toogle .app-overlay {
    background-color: #303030;
    z-index: 5;
  }

  .app-main-content {
    padding: 59px 0px 30px 0px;
  }
}
.display-flex {
  display: -webkit-flex;
  display: flex;
}

.display-flex-center {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.text-white {
  color: #fff;
}
