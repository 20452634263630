/* .toast-noti {
  display: flex;
  border-radius: 8px;
  width: 100%;
  align-items: center;
  &-icon {
    background-color: white;
    border-radius: 50%;
    margin-left: 21px;
    margin-right: 22px;
    img {
      width: 32px;
      height: 32px;
    }
  }
  &-content {
    font-size: 16px;
    font-weight: normal;
    color: #ffffff;
  }
} */
.toast-noti,
.toast-noti:hover {
  color: #fff;
  text-decoration: none;
}


.Toastify__toast--default.toast-modify {
  background-color: #4e4e4e;
  border-radius: 8px;
  width: 323px;
  height: 52px;
  font-family: DaxVN;
}

.Toastify__close-button.Toastify__close-button--default {
  color: #ffffff;
  opacity: 1;
  width: 9px;
  height: 9px;
}

@media only screen and (max-width: 480px) {
  .Toastify__toast-container.Toastify__toast-container--top-right {
    width: auto !important;
    padding: 0;
    left: auto;
  }
}