.hamburger {
  height: 18px;
  width: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.hamburger .navicon {
  background: #fff;
  display: block;
  height: 2px;
  position: relative;
  transition: 0.2s ease-out;
  width: 100%;
}

.hamburger .navicon::before,
.hamburger .navicon::after {
  background: #fff;
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  transition: all 0.2s ease-out;
  width: 100%;
}

.hamburger .navicon::before {
  top: 5px;
}

.hamburger .navicon::after {
  top: -5px;
}

.hamburger.toogle .navicon {
  background: transparent;
}

.hamburger.toogle .navicon:before {
  transform: rotate(-45deg);
}

.hamburger.toogle .navicon:after {
  transform: rotate(45deg);
}

.hamburger.toogle .navicon:before,
.hamburger.toogle .navicon:after {
  top: 0;
}